import React, { useEffect, useState } from "react";
import { STATIC_FILE_URL } from "../constants";
import { AppBar, Box, Button, Divider, Drawer, Fab, IconButton, List, ListItem, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from "react-router-dom";
import { bgGreen } from "../theme";

const Title = ({ isSmall }: { isSmall?: boolean}) => {
  return (
    <Box display="flex">
      <Typography
        variant={isSmall ? "h3" : "h2"}
        component="h3"
        style={{ letterSpacing: "0.02em" }}
      >
        Mappin
      </Typography>
      <img
        src={`${STATIC_FILE_URL}/pin-icon.png`}
        width="16px"
        height="16px"
        style={{ marginBottom: isSmall ? "0.5rem" : "0.75rem", alignSelf: "end" }}
        alt="mappin-pin"
      />
    </Box>
  )
}

export const CTAButton = ({ isSmall }: { isSmall?: boolean }) => {
  const history = useHistory();
  return (
    <Button
      variant="contained"
      size={isSmall ? "small" : "medium"}
      sx={{ background: "linear-gradient(45deg, #4D8383, #5CE1E6)" }}
      onClick={() => history.push("/create")}
    >
      Create your {isSmall ? "own" : ""} map
    </Button>
  )
}

export const Navbar = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const startNav = scrollPosition < 100;

  if (isMobile) {
    // return mobile navbar
    return (
      <>
        <AppBar
          position="sticky"
          sx={{
            bgcolor: startNav ? "transparent" : `${theme.palette.background.default}aa`,
            backdropFilter: startNav ? undefined : "blur(8px)",
            top: 0,
          }}
        >
          <Toolbar>
            <Box position="fixed" bgcolor={bgGreen} width="100%">
              <Typography variant="h5" color="white" textAlign="left" zIndex={10}>
                Mappin will be down for maintenance for the next 2 days. Paid maps will be unavialable.<br/> Sorry for any inconvenience caused.
              </Typography>
            </Box>
          </Toolbar>
          <Toolbar sx={{ justifyContent: "space-between", my: 0.5, bgcolor: "transparent", backgroundImage: "none" }}>
            <img
              src={`${STATIC_FILE_URL}/pin-icon.png`}
              width="32px"
              height="32px"
              style={{ alignSelf: "center" }}
              alt="mappin-pin"
            />
            <CTAButton isSmall/>
            <IconButton onClick={() => setIsOpen(!isOpen)}>
              <MenuIcon/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          open={isOpen}
          anchor="right"
          PaperProps={{ sx: { bgcolor: theme.palette.background.default, p: 1, alignItems: "center" }}}
          onClose={() => setIsOpen(false)}
        >
          <Title isSmall/>
          <Divider flexItem sx={{ mt: 1 }} />
          <List>
            <ListItem>
              <Typography variant="h6">About Us</Typography>
            </ListItem>
            <ListItem>
              <CTAButton isSmall/>
            </ListItem>
          </List>

        </Drawer>
      </>
    );
  }
  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: startNav ? "transparent" : `${theme.palette.background.default}aa`,
        backdropFilter: startNav ? undefined : "blur(8px)",
        backgroundImage: "none",
        top: 0,
      }}
    >
      <Toolbar>
            <Box position="fixed" bgcolor={bgGreen} width="100%">
              <Typography variant="h5" color="white" textAlign="left" zIndex={10}>
                Mappin will be down for maintenance for the next 2 days. Paid maps will be unavialable.<br/> Sorry for any inconvenience caused.
              </Typography>
            </Box>
          </Toolbar>
      <Toolbar sx={{ justifyContent: "space-between", my: isSmall ? 1 : 2, bgcolor: "transparent", backgroundImage: "none" }}>
        <Title />
        <Box display="flex">
          <Button sx={{ mr: isSmall ? "1rem" : "2rem", color: theme.palette.text.primary }} size={isSmall ? "small" : "medium"}>
            About Us
          </Button>
          <CTAButton/>
        </Box>
      </Toolbar>
      {startNav && (<Fab sx={{ position: "absolute", top: "90vh", left: "50%", background: "#000000aa" }} href="#features">
        <ExpandMoreIcon fontSize="large" sx={{ color: theme.palette.text.primary, ":hover": { color: theme.palette.text.secondary } }} />
      </Fab>)}
    </AppBar>
  );
};
