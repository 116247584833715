// @ts-ignore-line
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapbox, { LngLat, LngLatBounds } from "!mapbox-gl";
// import mapbox, { LngLat, LngLatBounds } from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import posthog from 'posthog-js'
import { MapIds } from "../constants";
import { useSelector, ThunkAction } from "./types";
import { MapStyle } from "../types";
import { Layout, PaperSize, BorderStyle } from "../components/mapmaker/types";
import { getPins } from "../components/mapmaker/pins";
import { BorderInfo, LayoutInfo } from "../components/mapmaker/constants";
import { triggerGenerateImage } from "./image";
import { clearBorder } from "../components/mapmaker/borders";

export type PinInfo = {
  lat: number;
  lng: number;
};

export enum Step {
  Location = "location",
  Pins = "pins",
  Style = "style",
  Layout = "layout",
  Border = "border",
  Size = "size"
}

const MIN_ZOOM_LIMIT = 7.5;
const NUM_PINS_LIMIT = 20;
export const PIN_WIDTH_PX = 16;
const PIN_HEIGHT_PX = 16;

const MARKER_CLASS_NAME = "mappin-marker";

export type MapState = {
  map: mapbox.Map | null;
  geoSearch: HTMLElement | null;
  style: MapStyle;
  layout: Layout;
  border: BorderStyle;
  borderState: Record<string, any> | null;
  size: PaperSize;
  step: Step;
  error: string | null;
};

const initialState: MapState = {
  map: null,
  geoSearch: null,
  style: MapStyle.LIGHT,
  layout: Layout.PORTRAIT,
  border: BorderStyle.NONE,
  borderState: null,
  size: PaperSize.SINGLE,
  step: Step.Location,
  error: null,
};

type Initialise = {
  type: MapAction.Init;
  map: mapbox.Map;
}

type SetStyle = {
  type: MapAction.SetStyle;
  style: MapStyle;
}

type SetLayout = {
  type: MapAction.SetLayout;
  layout: Layout;
}

type SetSize = {
  type: MapAction.SetSize;
  size: PaperSize;
}

type SetBorder = {
  type: MapAction.SetBorder;
  border: BorderStyle;
}

type SetBorderState = {
  type: MapAction.SetBorderState;
  borderState: MapState["borderState"];
}

type SetStep = {
  type: MapAction.SetStep;
  step: Step;
}

type SetError = {
  type: MapAction.SetError;
  error: string | null;
}

type Close = {
  type: MapAction.Close;
}

type MapActions = Initialise | SetStyle | SetLayout | SetSize | SetBorder | SetBorderState | SetStep | SetError | Close;

enum MapAction {
  Init = "initialise",
  Close = "close",
  SetLocation = "set-location",
  SetStyle = "set-style",
  SetLayout = "set-layout",
  SetSize = "set-size",
  SetBorder = "set-border",
  SetBorderState = "set-border-state",
  SetStep = "set-step",
  SetError = "set-error",
}

const getStyleId = (style: MapStyle): string => `mapbox://styles/amansanghvi/${MapIds[style]}`

export const MAPBOX_CONTAINER_ID = "mapbox-gl-js";

mapbox.accessToken = process.env.REACT_APP_MAPBOX_KEY as string;

export const mapStore = (state: MapState = initialState, action?: MapActions): MapState => {
  switch(action?.type) {
    case MapAction.Init:
      if (!state.map) {
        return { ...state, map: action.map };
      }
      return state;
    case MapAction.SetStyle:
      state.map?.setStyle(getStyleId(action.style))
      return { ...state, style: action.style };
    case MapAction.SetLayout:
      // state.map?.resize(); // TODO: Update dimensions
      return { ...state, layout: action.layout };
    case MapAction.SetSize:
      state.map?.setStyle(action.size);
      return { ...state, size: action.size };
    case MapAction.SetStep:
      return { ...state, step: action.step };
    case MapAction.SetBorder:
      return { ...state, border: action.border, ...(action.border !== BorderStyle.NONE && { borderState: null }) };
    case MapAction.SetBorderState:
      return { ...state, borderState: action.borderState };
    case MapAction.SetError:
      return { ...state, error: action.error };
    case MapAction.Close:
      return { ...state, map: null, geoSearch: null };
  }
  return state;
}

export const closeMap = (): ThunkAction => (dispatch) => {
  dispatch({ type: MapAction.Close });
}

export const initMap = (container: HTMLElement): ThunkAction => async (dispatch, getState) => {
  const mapState = getState().map;
  let bounds: null | LngLatBounds = null;
  const params = new URLSearchParams(window.location.search);
  const maxLat = params.get("maxLat");
  const maxLng = params.get("maxLng");
  const minLat = params.get("minLat");
  const minLng = params.get("minLng");
  if (maxLat && maxLng && minLat && minLng) {
    bounds = new LngLatBounds(
      { lng: parseFloat(minLng), lat: parseFloat(minLat) },
      { lng: parseFloat(maxLng), lat: parseFloat(maxLat) },
    );
  }
  let center: LngLat | null = null;
  const lng = params.get("lng");
  const lat = params.get("lat");
  if (lng && lat) {
    center = new LngLat(parseFloat(lng), parseFloat(lat));
  }

  if (!mapState.map) {
    const newMap = new mapbox.Map({
      container: container || "",
      style: getStyleId(mapState.style),
      ...(bounds ? { bounds } : { center: center || [2.1686, 41.3874], zoom: 8}),
      attributionControl: true,
      pitchWithRotate: false,
      dragRotate: false,
    });
    newMap.touchZoomRotate.disableRotation();
    newMap.on("click", onMapClick(newMap, /* TODO: Clear error */ () => {}));
    newMap.on("zoomend", (event) => {
      dispatch({
        type: MapAction.SetError,
        error: event.target.getZoom() > MIN_ZOOM_LIMIT ? null : "Zoom level too high - zoom in more",
      });
    });
    dispatch({ type: MapAction.Init, map: newMap });
  }
}

export const attachGeoSearch = (div: HTMLDivElement): ThunkAction => (_, getState) => {
  const { map } = getState().map;
  if (!map) {
    return;
  }
  const geocoder = new MapboxGeocoder({
    accessToken: process.env.REACT_APP_MAPBOX_KEY as string,
    mapboxgl: map,
    placeholder: "Pick your city or area",
    marker: false,
  });
  const el = geocoder.onAdd(map);
  el.style.setProperty("width", "100%");
  el.style.setProperty("max-width", "none");
  for (const child of div.childNodes) {
    div.removeChild(child);
  }
  div.appendChild(el);
  el.focus();
}

// TODO: Have a pop-over that comes up on click to suggest actions
export const onMapClick = (
  map: mapbox.Map,
  removalListener: () => void,
) => (e: mapbox.MapMouseEvent) => {
  if (!window.pinsEnabled) {
    return;
  }
  const pinInfo: PinInfo = {
    lat: e.lngLat.lat,
    lng: e.lngLat.lng,
  };

  const el = document.createElement("div")
  el.style.backgroundImage = "url('/pin-icon.png')";
  el.style.width = `${PIN_WIDTH_PX}px`;
  el.style.height = `${PIN_HEIGHT_PX}px`;
  el.style.backgroundSize = '100%';
  el.style.cursor = "pointer";
  // TODO: Make it so that pins don't get created if border exists.
  el.style.zIndex = "3";
  el.className = MARKER_CLASS_NAME;
  el.id = JSON.stringify(pinInfo);
  el.addEventListener("click", (e) => {
    if (!window.pinsEnabled) {
      return;
    }
    e.stopPropagation();
    removalListener();
    el.remove();
  });

  new mapbox.Marker(el)
    .setLngLat(e.lngLat)
    .setOffset(new mapbox.Point(PIN_WIDTH_PX/2, -PIN_HEIGHT_PX/2))
    .addTo(map);
};

export const submitMap = (): ThunkAction => (dispatch, getState) => {
  const { error, border, borderState, layout, size: paperSize, style, map } = getState().map;
  if (error || !map) {
    return;
  }

  if (map?.getZoom() < MIN_ZOOM_LIMIT) {
    return
  }

  if ([MapStyle.BLUE, MapStyle.TERRAIN, MapStyle.SATELLITE, MapStyle.WOOD, MapStyle.CARTOON].includes(style)) {
    // TODO: Undisable paid maps.
    return;
  }
  if (paperSize === PaperSize.A3) {
    // TODO: Undisable paid maps.
    return;
  }
  const pins = getPins(map);
  const topLeft = map.unproject([0, 0]);
  const {width: canvasWidth, height: canvasHeight} = map.getCanvas().getBoundingClientRect();
  const bottomRight = map.unproject([
    canvasWidth,
    canvasHeight,
  ]);
  const validPins = pins.filter((pin) =>
    pin.lat > bottomRight.lat
      && pin.lng < bottomRight.lng
      && pin.lat < topLeft.lat
      && pin.lng > topLeft.lng
  );
  if (validPins.length > NUM_PINS_LIMIT) {
    return;
  }
  const size = paperSize + LayoutInfo[layout].suffix;
  const data = {
    pins: validPins,
    pinScale: PIN_WIDTH_PX/canvasWidth,
    minLat: bottomRight.lat,
    minLng: topLeft.lng,
    maxLat: topLeft.lat,
    size,
    style,
    borderFilter: BorderInfo[border].id,
    borderState: borderState,
  };
  posthog.capture("generate_map_clicked", data);
  dispatch(triggerGenerateImage(data));
}

export const setStyle = (style: MapStyle): SetStyle => ({ type: MapAction.SetStyle, style });
export const setLayout = (layout: Layout): SetLayout => ({ type: MapAction.SetLayout, layout });
export const setSize = (size: PaperSize): SetSize => ({ type: MapAction.SetSize, size });
export const setBorder = (border: BorderStyle): ThunkAction => (dispatch) => {
  if (border === BorderStyle.NONE) clearBorder();
  dispatch({ type: MapAction.SetBorder, border });
};
export const setBorderState =
  (borderState: MapState["borderState"], drawFn: (map: mapbox.Map, state: MapState["borderState"]) => void): ThunkAction => (dispatch, getState) => {
    const { map } = getState().map;
    clearBorder();
    if (!map) return;
    drawFn(map, borderState);
    dispatch({ type: MapAction.SetBorderState, borderState })
  };
export const setStep = (step: Step): SetStep => ({ type: MapAction.SetStep, step });
export const setMapError = (error: string | null): SetError => ({ type: MapAction.SetError, error });

export const useMap = (): mapbox.Map | null => useSelector((state) => state.map.map);

export const useStyle = (): MapStyle => useSelector((state) => state.map.style);
export const useLayout = (): Layout => useSelector((state) => state.map.layout);
export const useSize = (): PaperSize => useSelector((state) => state.map.size);
export const useBorder = (): BorderStyle => useSelector((state) => state.map.border);
export const useBorderState = () => useSelector((state) => state.map.borderState);
export const useStep = (): Step => useSelector((state) => state.map.step);
export const useMapError = (): string | null => useSelector((state) => state.map.error);
