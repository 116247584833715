import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";
// @ts-ignore-next-line
import smoothscroll from "smoothscroll-polyfill";
import theme, { bgGreen } from "../theme";
import { Provider } from "react-redux";
import store from "../state";
import Checkout, { PaymentFailed } from "./checkout";
import ScrollToTop from "./utility/ScrollToTop";
import { GA_ID } from "../constants";
import Dashboard from "./dashboard";
import { Links } from "../types";
import MapMaker from "./mapmaker";
import { Box, Typography } from "@mui/material";

// kick off the polyfill!
smoothscroll.polyfill();

const App = () => {
  ReactGA.initialize(GA_ID);
  return (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Box position="fixed" bgcolor={bgGreen} width="100%">
      <Typography variant="h5" color="white" textAlign="left" zIndex={10}>
        Mappin will be down for maintenance for the next 2 days. Paid maps will be unavialable.<br/> Sorry for any inconvenience caused.
      </Typography>
    </Box>
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <StyledThemeProvider theme={theme as any}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path={Links.Home}>

              <Dashboard/>
            </Route>
            <Route exact path={Links.Create}>
              <MapMaker/>
            </Route>
            <Route exact path={Links.Checkout}>
              <Checkout />
            </Route>
            <Route exact path={Links.PaymentFailed}>
              <PaymentFailed />
            </Route>
          </Switch>
        </ThemeProvider>
        </StyledThemeProvider>
      </Router>
    </Provider>
  </Box>
  );
};

export default App;
